













































import Vue from "vue";
import Component from "vue-class-component";
import { Ref, Watch } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";
import ApiButton from "@/vue/components/ApiButton.vue";
import ConfirmDialogue from "@/vue/components/ConfirmDialogue.vue";
import apiClient from "@/utilities/ApiClient";
import store from "@/store/store";
import utils from "@/utilities/Utils";
import { ISaveResponse } from "@/model/ISaveResponse";
import { UserRole } from "@/model/Enums";
import { ILookupItem } from "@/model/LookupItem";
import { IUser, User } from "@/model/User";

@Component({ components: { ApiButton, ConfirmDialogue } })
export default class UserDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("userForm") private readonly userForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private user: User = new User();

    //
    // -- computed properties
    //

    private get saveButtonText() {
        return utils.hasDateValue(this.user.deleted) ? "Save and un-archive" : "Save";
    }

    private get isEditingSelf() {
        if (!this.user) return false;
        if (!this.$store.state.signedInUser) return false;
        return this.user.id === this.$store.state.signedInUser.id;
    }

    //
    // -- validations
    //

    private emailErrorMessages: Array<string> = [];

    @Watch("user.email")
    private async validateEmail() {
        this.emailErrorMessages = [];
        if (utils.isEmptyOrWhitespace(this.user.email))  return;

        const response: { isInUse: boolean } = await apiClient.get(`/api/user/emailIsInUse?email=${this.user.email}&userID=${this.user.id}`);
        if (response.isInUse) {
            this.emailErrorMessages.push("Email address is already in use");
            return;
        }
    }

    //
    // -- methods
    //

    private get canDelete() {
        return !this.user.isNew && !utils.hasDateValue(this.user.deleted);
    }

    add() {
        this.reset();
        this.dialogueTitle = "Add User";
        this.showDialogue = true;
    }

    async edit(id: string) {
        this.reset();
        this.dialogueTitle = "Edit User";
        const userData: IUser = await apiClient.get(`api/user/Load?id=${id}`);
        this.user.update(userData);
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.user);
        this.user.id = utils.emptyGuidValue;
        this.userForm?.resetValidation();
        this.emailErrorMessages = [];
    }

    private async save() {
        await this.validateEmail();
        const isValid = this.userForm.validate() && this.emailErrorMessages.length === 0;
        if (!isValid) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        if (store.state.signedInUser?.id != undefined) {
            this.user.landlordID = store.state.signedInUser?.landlordID;
        }

        const response: ISaveResponse = await apiClient.post("/api/user/save", this.user);
        toastr.success("Saved");
        this.$emit("saved");    
        this.showDialogue = false;
    }

    private confirmDelete() {
        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async doDelete() {
        await apiClient.post("/api/user/delete", this.user);
        toastr.warning("Deleted");
        this.$emit("saved");
        this.showDialogue = false;
    }

}
