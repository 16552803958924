import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ILandlordRegistration {
    id: string;
    name: string;
    address1: string;
    address2: string;
    address3: string;
    postTown: string;
    postcode: string;
    contactForename: string;
    contactSurname: string;
    jobTitle: string;
    emailAddress: string;
    phoneNumber: string;
    website: string;
    codeType: string;
    temporaryPassword: string;
    created: Date;
}

export class LandlordRegistration implements ILandlordRegistration {

    constructor(data?: ILandlordRegistration) {
        if (data) this.update(data);
    }

    update(data: ILandlordRegistration) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    name: string = "";
    address1: string = "";
    address2: string = "";
    address3: string = "";
    postTown: string = "";
    postcode: string = "";
    contactForename: string = "";
    contactSurname: string = "";
    jobTitle: string = "";
    emailAddress: string = "";
    phoneNumber: string = "";
    website: string = "";
    codeType: string = "";
    temporaryPassword: string = "";
    created: Date = new Date(utils.emptyDateValue);
}
