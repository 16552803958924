































































































import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store/store";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ChangePasswordDialogue from "@/vue/components/ChangePasswordDialogue.vue";
import UserDialogue from "@/vue/components/UserDialogue.vue";
import { UserRole } from "@/model/Enums";
import { User } from "@/model/User";
import { ILookupItem } from '@/model/LookupItem';
import { LandlordRegistration, ILandlordRegistration } from '@/model/LandlordRegistration';
import utils from "@/utilities/Utils";

@Component({
    components: { ApiButton, ChangePasswordDialogue, UserDialogue }
})
export default class Profile extends Vue {

    //
    // -- lifecycle hooks
    //

    async mounted() {
        if (store.state.signedInUser == null) {
            await store.dispatch("loadSignedInUser");
        }
        if (store.state.signedInUser !== null) {
            this.user = store.state.signedInUser;
            if (this.isLandlord) {
                this.loadLandlordRegistrationDetails(this.user.id);
            }
        }
    }

    //
    // -- properties
    //

    private user = new User();
    private landlordRegistration = new LandlordRegistration();

    private get isLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser;
    }

    //
    // -- methods
    //

    async reload() {
        if (store.state.signedInUser == null) {
            await store.dispatch("loadSignedInUser");
        }
        if (store.state.signedInUser !== null) {
            this.user = store.state.signedInUser;
            if (this.isLandlord) {
                this.loadLandlordRegistrationDetails(this.user.id);
            }
        }
    }

    private get roleOptions(): Array<ILookupItem> {
        return utils.enumToLookups(UserRole);
    }

    private changePassword() {
        const dialogue: ChangePasswordDialogue = this.$refs.changePasswordDialogue as ChangePasswordDialogue;
        dialogue.changePassword(this.user);
    }

    private editDetails() {
        const dialog: UserDialogue = this.$refs.userDialogue as UserDialogue;
        dialog.edit(this.user.id);
    }

    async loadLandlordRegistrationDetails(id: string) {
        const registrationData: ILandlordRegistration = await apiClient.get(`api/landlord/loadLandlordRegistrationDetails?id=${id}`);
        this.landlordRegistration.update(registrationData);
    }

}
